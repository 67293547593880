import containerAdapter from '@adapters/container'
import heroAdapter from '@adapters/hero'
import UniversalProps from '@common/UniversalProps.interface'
import Page from '@components/blocks/Page'
import Hero from '@components/global/Hero'
import Layout from '@components/layout'
import { Theme } from '@components/shared'
import ContainerManager from '@components/shared/ContainerManager'
import fetchPage from '@utils/fetchPage'
import isNullOrUndefined from '@utils/isNullOrUndefined'
import delve from 'dlv'
import { GetStaticProps, GetStaticPropsContext } from 'next'
import ErrorPage from 'next/error'

const NotFound = ({ globalData, pageData, origin, preview }: UniversalProps) => {
  if (pageData == null) {
    return <ErrorPage statusCode={404} />
  }
  const page = delve(pageData, 'attributes')
  page.theme = isNullOrUndefined(page.theme) ? Theme.GREEN : page.theme
  const hero = heroAdapter(delve(pageData, 'attributes.hero.data'))
  hero.theme = isNullOrUndefined(hero.theme) ? page.theme : hero.theme
  const containers = (delve(pageData, 'attributes.containers.data') ?? []).map(containerAdapter)

  return (
    <Layout globalData={globalData} pageData={pageData} preview={preview} origin={origin}>
      <Page {...page}>
        {hero && (
          <Hero {...hero} image={hero.image}>
            {hero.containers && (
              <ContainerManager theme={hero.theme} containers={hero.containers} />
            )}
          </Hero>
        )}
        {containers && <ContainerManager containers={containers} theme={page.theme} />}
      </Page>
    </Layout>
  )
}
export const getStaticProps: GetStaticProps = async (context: GetStaticPropsContext) => {
  const { locale } = context

  try {
    const page = await fetchPage('404', locale ?? 'en', context.preview)

    if (!page) {
      throw new Error('404 Page not found')
    }

    return {
      props: { pageData: page, preview: context.preview || null },
    }
  } catch (error) {
    return {
      props: { pageData: null },
    }
  }
}

export default NotFound
